<template>
  <div class="home">
    <div>
      <van-image width="100px" height="100px" style="padding: 20px; padding-bottom: 0;" fit="contain" src="https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_processing.png" />
    </div>
    <div class="theme-color" style="margin-top: 15px; margin-bottom: 15px;">已提交</div>
    <div class="tip theme-color">
      <span>我们在尽快审批，您可登录查询进展，审批后我们将短信通知，请留意！</span><br/><br/>
    </div>
  </div>
</template>

<script>
import { Image as VanImage } from 'vant';
import { useStore } from 'vuex';
import { onMounted } from 'vue';

export default {
  name: 'Home',
  components: {
    [VanImage.name]: VanImage,
  },
  data() {
    return {};
  },
  setup() {
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '提交完成');
      store.commit('setStepsShow', false);
    });
    return {};
  },
};
</script>

<style lang="less">
  .tip {
    text-align: left;
    padding: @space-0;
    padding-top: 0;
  }
</style>
